import React from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { reducer, initialState } from "./reducer";
import Toast from "../../../helpers/toastr";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";

import { mainApi } from "../../../services/api";
import useModuleSelect from "../../../hooks/useModuleSelect";
import ModuleSelect from "../../../components/Common/ModuleSelect";
import DataTable from "../../../components/Common/DataTable";

const GradeCompletive = (props) => {
  const {
    state,
    getSubjectGrades,
    handleInputChange,
    getOptions,
    find,
  } = useModuleSelect({
    reducer,
    initialState,
  });
  // console.log(state);
  const {
    eachEntry,
   
    optionsSubjects,
    dataTable,
    isDataLoading,
    errors,
    
  } = state;
const { subject_id, period_id } = eachEntry;
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleSubmit =(e)=>{
      e.preventDefault();
      let input = e.target.firstChild;
     
    
      const formData = new FormData();
      let value=input.value;
      let id = input.getAttribute("dataid");
      let name = input.getAttribute("dataname");
     
     
        formData.append(name,value)
        formData.append("completive_stusubjects_id", id);
      
      mainApi
        .post(`/grades/completive`,formData 
        )
        .then((response) => {
            if (response.data.success) {
              
                Toast("success", "Grado", response.data.message);
            }
            else{
                Toast("error", "Grado", response.data.message);

            }
       
        });
        
      
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Grade")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody className="pt-2">
                <CardTitle>{props.t("Add Exam Completives Grades")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("Fill all information below")}
                </CardSubtitle>

                <ModuleSelect
                  props={props}
                  handleInputChange={handleInputChange}
                  getOptions={getOptions}
                  eachEntry={eachEntry}
                  errors={errors}
                  subject={true}
                  period ={false}
                  actions={
                    <Button
                      onClick={() => {
                        find(
                          `/grades/completive/subject/${subject_id.value.value}`
                        );
                      }}
                      type="submit"
                      color="primary"
                      className=" mt-4 waves-effect waves-light"
                      disabled={isDataLoading}
                    >
                      {props.t("Find")}
                    </Button>
                  }
                  getSubjectGrades={getSubjectGrades}
                  optionsSubjects={optionsSubjects}
                 
                  isDataLoading={isDataLoading}
                />

                {isDataLoading && <div>Cargando..</div>}
                {dataTable && !isDataLoading && (
                  <>
                    <DataTable data={dataTable} onSubmit={handleSubmit} />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withNamespaces()(GradeCompletive);
