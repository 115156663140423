export const initialInputState = {
  level_id: {
    label: "Level",
    value: "",
    errors: [],
    type: "select",
    rules: {
      require: true,
    },
  },

  
};
