import React,{useState} from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { reducer, initialState } from "./reducer";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  FormGroup,
  Button
} from "reactstrap";
import SelectFetch from "../../../components/Common/SelectFetch";
import useModuleSelect from "../../../hooks/useModuleSelect";
 import Select from "react-select";
 import DataTable from "../../../components/Common/DataTable";
 import { mainApi } from "../../../services/api";
 import Toast from "../../../helpers/toastr";
const GradeConf = (props) => {

const [sendingData, setSendingData] = useState(true)
                   
const { state, handleInputChange, getOptions, find, handleDispatch } =
  useModuleSelect({
    reducer,
    initialState,
  });

// console.log(state);
const {
  eachEntry,
//   optionsPeriods,
  optionsSubjects,
  dataTable,
  isDataLoading,
  errors,
} = state;

const { course_id, subject_id } = eachEntry;

const handleCheckBoxes =(e)=>{
 e.stopPropagation();
 setSendingData(false);
 const { name, value } = e.target;
 const newState = dataTable.rows.map((obj) => {
   return obj.competence_id === name
     ? {
         ...obj,
         [value]: !obj[value],
       }
     : obj;
 });

// const row =e.target.getAttribute("data-row") ;
// console.log(row);
//  handleDispatch("CHECKBOX_CHANGE",newState);
   const formData = new FormData();
   formData.append("competence_id", name);
   formData.append("period_id", value);
   formData.append("course_id",course_id.value);
   formData.append("subject_id", subject_id.value.value);


 mainApi.post(`/grades/inicial-configuration`, formData).then((response) => {
   if (response.data.success) {
     handleDispatch("CHECKBOX_CHANGE", newState);
     setSendingData(true);
     Toast("success", "Configuracion", "Agregado Correctamente");
   } else {
     Toast("error", "Configuracion", "No es posible realizar los cambios");
     setSendingData(true);
   }
 });

}



  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Grades")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("Grades Configuration")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("Fill all information below")}
                </CardSubtitle>
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <Label>{props.t("Courses")}</Label>
                      <SelectFetch
                        url={"courses/select/all"}
                        minMenuHeight={600}
                        value={course_id.value}
                        onChange={(selected) => {
                          handleInputChange("course_id", selected);
                          getOptions(`courses/selects/${selected.value}`);
                        }}
                        placeholder={props.t("Select a Course")}
                      />
                      <small className="text-danger">{errors?.course_id}</small>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="select2-container">
                      <Label>{props.t("Subjects")}</Label>
                      <Select
                        menuPortalTarget={document.body}
                        value={subject_id.value}
                        onChange={(selected) =>
                          handleInputChange("subject_id", selected)
                        }
                        options={optionsSubjects}
                        placeholder={props.t("First select a course")}
                      />
                      <small className="text-danger">
                        {errors?.subject_id}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <Button
                        onClick={() => {
                          let url = `/grades/inicial-configuration/subject/${subject_id.value.value}`;

                          find(url);
                        }}
                        type="submit"
                        color="primary"
                        className=" mt-4 waves-effect waves-light"
                        disabled={isDataLoading}
                      >
                        {props.t("Find")}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                {dataTable && !isDataLoading && (
                  <>
                    <div
                      className="table-responsive"
                      style={
                        sendingData
                          ? {}
                          : { pointerEvents: "none", opacity: "0.4" }
                      }
                    >
                      <DataTable data={dataTable} onChange={handleCheckBoxes} />
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withNamespaces()(GradeConf);
