import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";


export const AttendanceControl = (props) => {
 
    return (
      <>
        <Card className="bg-warning">
          <CardBody className="mb-1">
            <CardTitle className="mb-5">
              <h3>{props.t("Attendance Control")}</h3>
            </CardTitle>
            <h4>{props.t("Present Student Today")}: 0 </h4>
          </CardBody>
        </Card>
      </>
    );
}



export default AttendanceControl;
