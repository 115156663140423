import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import StudentList from "../pages/Students/All Students/";
import StudentAdd from "../pages/Students/Admission Form";
import StudentPromotion from "../pages/Students/Student Promotion";

import GradeAdd from "../pages/Grades/Add";
import GradeConfig from "../pages/Grades/configuration";
import GradeComments from "../pages/Grades/Comments";

import GradeAddConduct from "../pages/Grades/Add Conduct";
import GradeCompletive from "../pages/Grades/Completive";
import ReportsBulletin from "../pages/Reports/Bulletin";
import ReportsUsers from "../pages/Reports/pdf/users";
import ReportsCompetence from "../pages/Reports/Competence";
import studentFinances from "../pages/Finances/Status/";
import PeriodSetting from "../pages/Admin/Settings/Period";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/students/list", component: StudentList },
  { path: "/students/add", component: StudentAdd },
  { path: "/finance/student", component: studentFinances },
  { path: "/students/promotion", component: StudentPromotion },
  { path: "/grade/add", component: GradeAdd },
  { path: "/grade/configuration", component: GradeConfig },
  { path: "/grade/comments", component: GradeComments },
  { path: "/grade/completive", component: GradeCompletive },

  { path: "/reports/bulletin", component: ReportsBulletin },
  { path: "/reports/competence", component: ReportsCompetence },
  { path: "/reports/users", component: ReportsUsers },

  { path: "/grade/conduct", component: GradeAddConduct },
  { path: "/admin/settings/period", component: PeriodSetting },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
