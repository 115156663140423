import { useReducer, useEffect } from "react";
import useFetch from "./useFetch";
import Toast from "../helpers/toastr";
import { handleTable } from "../helpers/handleTables";
import { handleValidationBeforeSubmit } from "../helpers/validator";

import { mainApi } from "../services/api";

export const useCrud = ({ reducer, initialState, WorkWith, validate }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isMode, eachEntry, isSubmit, id, errors } = state;
  const { singular, plural, pluralM, readUrl } = WorkWith;
  const { response, isLoading, isError } = useFetch({
    api: mainApi,
    method: "get",
    url: `/${readUrl ? readUrl : plural}`,
    //config: JSON.stringify({ requireAuthentication: true }),
  });
  //const { errors, valid, handleValidation, handleResetError } = useValidator();

  /*____________________________________________________________________________________________________________________________________________________ */
  const toggle = () => {
    dispatch({ type: "INITIAL_STATE" });
  };
  const toggleAdd = () => {
    dispatch({ type: "CREATE_START" });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const getrow = (row, option) => {
   
    if (option === "update") {
      dispatch({ type: "UPDATE_START", payload: row });
    } else if (option === "delete") {
      dispatch({ type: "DELETE_START", payload: row });
    }
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleInputChange = (key, value) => {
    if (errors?.[key]) {
      dispatch({ type: "RESET_AN_ENTRY_ERROR", payload: [key] });
    }
    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        ...eachEntry,
        [key]: { ...eachEntry?.[key], value: value },
      },
    });
  };

  /*____________________________________________________________________________________________________________________________________________________ */
  useEffect(() => {
    if (response !== null) {
      dispatch({
        type: "READ",
        payload: handleTable(response[plural], getrow),
      });
    }
  }, [response, plural]);
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleSubmit = () => {
    let checkErrors = handleValidationBeforeSubmit(eachEntry);
    if (JSON.stringify(checkErrors) !== "{}" && isMode !== "Delete") {
   
      dispatch({ type: "SET_ERRORS", payload: checkErrors });
    } else {
      if (isSubmit) {
        return;
      }
      dispatch({ type: "SENDING_DATA_TO_SERVER" });
      switch (isMode) {
        case "Update":
          handleUpdate();
          break;
        case "Delete":
          handleDelete();
          break;
        default:
          handleCreate();
          break;
      }
    }
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCreate = () => {
    const formData = new FormData();
    Object.entries(eachEntry).forEach(([key, value]) => {
      if (value.type === "text") {
        formData.append(key, value.value);
       
      } else if (value.type === "select") {
     
        formData.append(key, value.value.value);
      } else if (value.type === "date") {
      
        let date = new Date(value.value)
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");

        formData.append(key, date);
      } else if (value.type === "radio") {
       
        formData.append(key, value.value);
      }
    });
    mainApi.post(`${plural}`, formData).then((res) => {
      if (res.data.sucess) {
        const newItem = handleTable([res.data[singular]], getrow);
        dispatch({ type: "ADDED", payload: newItem });
        dispatch({ type: "INITIAL_STATE" });
        Toast("success", pluralM, res.data.message);
      }
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleUpdate = () => {
    const formData = new FormData();
    Object.entries(eachEntry).forEach(([key, value]) => {
      formData.append(key, value.value);
    });
    mainApi
      .post(`${plural}/${id}`, formData)
      .then((res) => {
        if (res.data.sucess) {
          const newItem = handleTable([res.data[singular]], getrow);
          dispatch({ type: "UPDATED", payload: newItem });
          Toast("success", pluralM, res.data.message);
          dispatch({ type: "INITIAL_STATE" });
        } else {
        
        }
      })
      
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleDelete = () => {
    mainApi.post(`${plural}/${id}/delete`).then((res) => {
      if (res.data.sucess) {
        dispatch({ type: "DELETED" });
        Toast("success", pluralM, res.data.message);
        dispatch({ type: "INITIAL_STATE" });
      } else {
       
      }
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleFilter = (e) => {
    dispatch({
      type: "FILTER_DATA",
      payload: e.target.value,
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */

  const handleDispatch = (type, payload) => {
    dispatch({
      type: type,
      payload: payload,
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */

  return {
    state,
    toggle,
    toggleAdd,
    handleInputChange,
    handleDispatch,
    handleSubmit,
    handleFilter,
    isLoading,
    isError,
    errors,
  };
};
