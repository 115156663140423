import React from "react";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DataTable from "../../../components/Common/DataTable";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useCrud } from "../../../hooks/useCrud";
import Skeleton from "react-loading-skeleton";
import { reducer, initialState } from "../reducer";
import { initialInputState } from "../validate";
import { mainApi } from "../../../services/api";
import Toast from "../../../helpers/toastr";
import { Link } from "react-router-dom";
const WorkWith = {
  singular: "student",
  plural: "students",
  pluralM: "students",
  readUrl: "finances/student/status",
};
const List = (props) => {
  const { state, isLoading, isError, handleDispatch, handleFilter } = useCrud({
    reducer,
    initialState,
    WorkWith,
    initialInputState,
  });
  const { data, isMode, filter, status } = state;
  const { pluralM } = WorkWith;

  const hangleOnchange = (e) => {
    e.stopPropagation();
    const { name, value } = e.target;
    const newState = state.data.rows.map((obj) => {
      return obj.user_id === name
        ? {
            ...obj,
            1: false,
            2: false,
            [value]: true,
          }
        : obj;
    });
    handleDispatch("RADIO", newState);
    const formData = new FormData();
    formData.append("student_id", name);
    formData.append("student_status_id", value);
    mainApi.post(`/finances/student/status`, formData).then((response) => {
      if (response.data.success) {
        Toast("success", "Estado", "Actualizado Correctamente");
      }
    });
  };
  const search = () => {
    if (status) {
      return data.rows.filter((x) => {
        return x?.[status] === true;
      });
    } else {
      return data.rows.filter(
        (data) =>
          JSON.stringify(data).toLowerCase().indexOf(filter.toLowerCase()) > -1
      );
    }
  };

  /*____________________________________________________________________________________________________________________________________________________ */
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t(pluralM)}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="row mb-2">
                    <div className="col-sm-2">
                      <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar..."
                            value={filter}
                            onChange={(e) => handleFilter(e)}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 ">
                      <ul className="list-group list-group-horizontal">
                        <li className="list-group-item border-0">
                          <Link
                            to="#"
                            onClick={() => {
                              handleDispatch("FILTER_BY_STATUS", "");
                            }}
                          >
                            Todos
                          </Link>
                          <span className="badge badge-primary badge-pill">
                            {data && data?.rows.length}
                          </span>
                        </li>
                        <li className="list-group-item border-0">
                          <Link
                            to="#"
                            onClick={() => {
                              handleDispatch("FILTER_BY_STATUS", "1");
                            }}
                          >
                            {" "}
                            Al día
                          </Link>
                          <span className="badge badge-primary badge-pill">
                            {data &&
                              data?.rows.filter((x) => {
                                return x?.["1"] === true;
                              }).length}
                          </span>
                        </li>
                        <li className="list-group-item border-0 ">
                          <Link
                            to="#"
                            onClick={() => {
                              handleDispatch("FILTER_BY_STATUS", "2");
                            }}
                          >
                            Retrasado
                          </Link>
                          <span className="badge badge-primary badge-pill">
                            {data &&
                              data?.rows.filter((x) => {
                                return x?.["2"] === true;
                              }).length}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {isLoading ? (
                    <Skeleton count={12} />
                  ) : (
                    <div className="table-responsive">
                      <DataTable
                        data={{
                          columns: [...data.columns],
                          rows: search(),
                        }}
                        pages={10}
                        onChange={hangleOnchange}
                      />
                    </div>
                  )}
                  {isError && <div>Error fechting data..</div>}
                </CardBody>
              </Card>
            </Col>
            {isMode}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default withNamespaces()(List);
