import React from "react";
import Logo_oikos from "../../../../assets/images/logos/logo_oikos.jpg";
import logo_ccmb from "../../../../assets/images/logos/logo_ccmb.jpg";
import logo_acsi from "../../../../assets/images/logos/logo_acsi.jpg";

import AcademicCondition from "../AcademicCondition";

import "../style.css";
import Attendance from "../Attendance";
import Signatures from "../Signatures";
import Leyenda from "./Leyenda";
import { criteria } from "./Criteria";
import { Button } from "reactstrap";

  


const PrimariaBulletin = ({ data,literal,generatePDF }) => {
  return (
    <div>
      <hr className="d-print-none"></hr>
      <table className="headertop" width="100%" id="jose">
        <tbody>
          <tr>
            <td className="cabezal" width="16.66%">
              <img src={Logo_oikos} alt="oikos" width={100} />
            </td>
            <td className="cabezal" width="16.66%">
              <img src={logo_ccmb} alt="ccmb" width={100} />
            </td>
            <td className="cabezal" width="16.66%">
              <img src={logo_acsi} alt="acsi" width={100} />
            </td>
            <td valign="top" align="center" width="15%">
              <img src={data?.general.avatar} alt="students" width="80"></img>
            </td>

            <td className="names" width="35%">
              {data?.user_name + " " + data?.user_last_name}
              <br />
              {data?.student_code ? data?.student_code : "0000-00000-000"}
              <br />
              {data?.course_name}
              <Button
                className=" mt-4 ml-2 waves-effect waves-light"
                color="success"
                onClick={() => generatePDF(data)}
              >
                <i className="fa fa-print"></i>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {/* header end */}
      {data?.student_status_id === "1" || data?.role_id < 3 ? (
        <>
          {" "}
          <table
            className="beta bulletin"
            width="100%"
            border={0}
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              <tr>
                <td colSpan={13} className=" text-center font-weight-bold">
                  Año Escolar 2022 - 2023
                </td>
              </tr>
              {/* subjets */}
              <tr>
                <td width="4.6%" className="p-2"></td>
                {data?.p_1 &&
                  data.p_1.map((item, index) => {
                    return (
                      <td
                        key={index + "subject"}
                        className="text-center font-weight-bold"
                        width="7.8%"
                      >
                        {item.subject_name}
                      </td>
                    );
                  })}
              </tr>
              {/* subjets END */}
              <tr>
                <td className="text-center font-weight-bold p-2">1</td>
                {data?.p_1 &&
                  data.p_1.map((item, index) => {
                    return (
                      <td
                        key={index + "grade"}
                        className="text-center"
                        width="7.8%"
                      >
                        {literal
                          ? criteria(item.final_grade)
                          : item.final_grade}
                      </td>
                    );
                  })}
              </tr>
              <tr>
                <td className="text-center font-weight-bold p-2">2</td>
                {data?.p_2
                  ? data.p_2.map((item, index) => {
                      return (
                        <td
                          key={index + "p_2"}
                          className="text-center "
                          width="7.8%"
                        >
                          {literal
                            ? criteria(item.final_grade)
                            : item.final_grade}
                        </td>
                      );
                    })
                  : data.p_1.map((item, index) => {
                      return (
                        <td
                          key={index + "p_2"}
                          className="text-center"
                          width="7.8%"
                        ></td>
                      );
                    })}
              </tr>
              <tr>
                <td colSpan={13} className="pt-4 pl-2 font-weight-bold">
                  Promedio 1er Semestre
                </td>
              </tr>
              <tr>
                <td className="pl-2">PRO.</td>
                {data?.PRO1 &&
                  data.PRO1.map((item, index) => {
                    return (
                      <td
                        key={index + "pro"}
                        className="text-center p-2"
                        width="7.8%"
                      >
                        {literal
                          ? criteria(item.final_grade)
                          : item.final_grade}
                      </td>
                    );
                  })}
              </tr>
              <tr>
                <td colSpan={13} className="pt-4">
                  &nbsp;
                  <br />
                </td>
              </tr>

              {/* subjets */}
              <tr>
                <td width="4.6%" className="p-2"></td>
                {data?.p_1 &&
                  data.p_1.map((item, index) => {
                    return (
                      <td
                        key={index + "subject2"}
                        className="text-center font-weight-bold"
                        width="7.8%"
                      >
                        {item.subject_name}
                      </td>
                    );
                  })}
              </tr>
              {/* subjets END */}
              <tr>
                <td className="text-center font-weight-bold p-2">3 </td>
                {data?.p_3
                  ? data.p_3.map((item, index) => {
                      return (
                        <td
                          key={index + "p_3"}
                          className="text-center "
                          width="7.8%"
                        >
                          {literal
                            ? criteria(item.final_grade)
                            : item.final_grade}
                        </td>
                      );
                    })
                  : data.p_1.map((item, index) => {
                      return (
                        <td
                          key={index + "p_3"}
                          className="text-center"
                          width="7.8%"
                        ></td>
                      );
                    })}
              </tr>
              <tr>
                <td className="text-center font-weight-bold p-2">4</td>
                {data?.p_4
                  ? data.p_4.map((item, index) => {
                      return (
                        <td
                          key={index + "p_4"}
                          className="text-center "
                          width="7.8%"
                        >
                          {literal
                            ? criteria(item.final_grade)
                            : item.final_grade}
                        </td>
                      );
                    })
                  : data.p_1.map((item, index) => {
                      return (
                        <td
                          key={index + "p_4"}
                          className="text-center"
                          width="7.8%"
                        ></td>
                      );
                    })}
              </tr>
              <tr>
                <td colSpan={13} className="pt-4 pl-2 font-weight-bold">
                  Promedio 2do Semestre
                </td>
              </tr>
              <tr>
                <td className="notasfix pl-2 p-2">PRO.</td>
                {data?.PRO2
                  ? data.PRO2.map((item, index) => {
                      return (
                        <td
                          key={index + "pro2"}
                          className="text-center p-2"
                          width="7.8%"
                        >
                          {literal
                            ? criteria(item.final_grade)
                            : item.final_grade}
                        </td>
                      );
                    })
                  : data.p_1.map((item, index) => {
                      return (
                        <td
                          key={index + "p_4"}
                          className="text-center"
                          width="7.8%"
                        ></td>
                      );
                    })}
              </tr>
              <tr>
                <td colSpan={13} className="pt-4 pl-2 font-weight-bold">
                  PROMEDIO FINAL:
                </td>
              </tr>
              <tr>
                <td className="pl-2 p-2">REC.</td>
                {data?.PROFIN
                  ? data.PROFIN.map((item, index) => {
                      return (
                        <td
                          key={index + "proFin"}
                          className="text-center p-2"
                          width="7.8%"
                        >
                          {literal && item.final_grade_completive
                            ? criteria(item.final_grade_completive)
                            : item.final_grade_completive}
                        </td>
                      );
                    })
                  : data.p_1.map((item, index) => {
                      return (
                        <td
                          key={index + "proFin"}
                          className="text-center p-2"
                          width="7.8%"
                        ></td>
                      );
                    })}
              </tr>
              <tr>
                <td className="pl-2 p-2">PRO. FIN.</td>
                {data?.PROFIN
                  ? data.PROFIN.map((item, index) => {
                      return (
                        <td
                          key={index + "proFin"}
                          className="text-center p-2"
                          width="7.8%"
                        >
                          {literal
                            ? criteria(item.final_grade)
                            : item.final_grade}
                        </td>
                      );
                    })
                  : data.p_1.map((item, index) => {
                      return (
                        <td
                          key={index + "proFin"}
                          className="text-center p-2"
                          width="7.8%"
                        ></td>
                      );
                    })}
              </tr>
            </tbody>
          </table>
          <AcademicCondition condition={data.condition} />
          <Attendance attendance={data?.general.attendance} />
          <Leyenda />
          <Signatures
            coordinator={data?.general.coordinator}
            teacher={data?.general.teacher}
            director={data?.general.director}
          />
        </>
      ) : (
        <div>
          <div className="text-center">
            <span style={{ fontSize: "3em", color: "Tomato" }}>
              <i className="fas fa-exclamation-triangle fa-3x" />
            </span>
          </div>

          <h2 className="text-center font-weight-bold">
            Por favor comunícate con la Administración
          </h2>
        </div>
      )}
    </div>
  );
};
export default PrimariaBulletin;
