import React, { useState } from "react";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import "./dataTable.css";
import Paginations from "./Paginations";

import { Pages } from "./Pages";
const DataTable = ({ data, onSubmit, onChange, props, pages }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(pages ? pages : 10);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;

  const handlePaginate = (e, number) => setCurrentPage(number);

  return (
    <>
      {data ? (
        <>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <TableHeader columns={data.columns} />
              </tr>
            </thead>
            <tbody>
              <TableBody
                columns={data.columns}
                rows={data.rows}
                onSubmit={onSubmit}
                onChange={onChange}
                indexOfLastData={indexOfLastData}
                indexOfFirstData={indexOfFirstData}
                // rowUpdate={this.handleRowUpdate}
                // filterBy={this.props.filter}
              />
            </tbody>
          </table>
          <div className="container">
            <div className="row">
              <div className="col-sm-2">
                <div>
                  <Pages setDataPerPage={setDataPerPage} /> Entradas
                </div>
              </div>
              <div className="col-sm-10">
                <Paginations
                  dataPerPage={dataPerPage}
                  totalData={data.rows?.length}
                  paginate={handlePaginate}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Data error</div>
      )}
    </>
  );
};

export default DataTable;
