import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";

const Navbar = (props) => {
  // const [dashboard, setdashboard] = useState(false);

  const [app, setapp] = useState(false);
   const [setting, setSetting] = useState(false);
  const [adminApp, setAdminApp] = useState(false);
    //const [email, setemail] = useState(false);

  //   const [crypto, setcrypto] = useState(false);
  const [grade, setgrade] = useState(false);
  const [reports, setReports] = useState(false);
  const [role, setRole] = useState("");
  //   const [task, settask] = useState(false);
  //   const [contact, setcontact] = useState(false);
  //   const [blog, setBlog] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setRole(obj.role);

    // role: "coordinator";
  }, [role, props]);
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link  arrow-none"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setdashboard(!dashboard);
                    // }}
                    to="index"
                  >
                    <i className="bx bx-home-circle mr-2"></i>
                    {props.t("Dashboard")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                  {/* <div
                      className={classname("dropdown-menu", {
                        show: dashboard,
                      })}
                    >
                      <Link to="index" className="dropdown-item">
                        {props.t("Default")}
                      </Link>
                    </div> */}
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-customize mr-2"></i>
                    {props.t("Apps")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    {/* <Link to="calendar" className="dropdown-item">
                      {props.t("Calendar")}
                    </Link> */}
                    {/* <Link to="chat" className="dropdown-item">
                      {props.t("Chat")}
                    </Link>
                    <Link to="apps-filemanager" className="dropdown-item">
                      {props.t("File Manager")}
                    </Link> */}
                    {/* <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setemail(!email);
                        }}
                      >
                        {props.t("Email")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: email })}
                      >
                        <Link to="email-inbox" className="dropdown-item">
                          {props.t("Inbox")}
                        </Link>
                        <Link to="email-read" className="dropdown-item">
                          {props.t("Read Email")}
                        </Link>
                        <div className="dropdown">
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setemail(!email);
                            }}
                          >
                            <span key="t-email-templates">Templates</span>{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: email,
                            })}
                          >
                            <Link
                              to="email-template-basic"
                              className="dropdown-item"
                            >
                              {props.t("Basic Action")}
                            </Link>
                            <Link
                              to="email-template-alert"
                              className="dropdown-item"
                            >
                              {props.t("Alert Email")}
                            </Link>
                            <Link
                              to="email-template-billing"
                              className="dropdown-item"
                            >
                              {props.t("Billing Email")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setecommerce(!ecommerce);
                        }}
                      >
                        {props.t(" Ecommerce")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: ecommerce,
                        })}
                      >
                        <Link to="ecommerce-products" className="dropdown-item">
                          {props.t("Products")}
                        </Link>
                        <Link
                          to="ecommerce-product-detail"
                          className="dropdown-item"
                        >
                          {props.t("Product Detail")}
                        </Link>
                        <Link to="ecommerce-orders" className="dropdown-item">
                          {props.t("Orders")}
                        </Link>
                        <Link
                          to="ecommerce-customers"
                          className="dropdown-item"
                        >
                          {props.t("Customers")}
                        </Link>
                        <Link to="ecommerce-cart" className="dropdown-item">
                          {props.t("Cart")}
                        </Link>
                        <Link to="ecommerce-checkout" className="dropdown-item">
                          {props.t("Checkout")}
                        </Link>
                        <Link to="ecommerce-shops" className="dropdown-item">
                          {props.t("Shops")}
                        </Link>
                        <Link
                          to="ecommerce-add-product"
                          className="dropdown-item"
                        >
                          {props.t("Add Product")}
                        </Link>
                      </div>
                    </div> */}

                    {/* <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setcrypto(!crypto);
                        }}
                      >
                        {props.t("Crypto")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: crypto })}
                      >
                        <Link to="crypto-wallet" className="dropdown-item">
                          {props.t("Wallet")}
                        </Link>
                        <Link to="crypto-buy-sell" className="dropdown-item">
                          {props.t("Buy/Sell")}
                        </Link>
                        <Link to="crypto-exchange" className="dropdown-item">
                          {props.t("Exchange")}
                        </Link>
                        <Link to="crypto-lending" className="dropdown-item">
                          {props.t("Lending")}
                        </Link>
                        <Link to="crypto-orders" className="dropdown-item">
                          {props.t("Orders")}
                        </Link>
                        <Link
                          to="crypto-kyc-application"
                          className="dropdown-item"
                        >
                          {props.t("KYC Application")}
                        </Link>
                        <Link to="crypto-ico-landing" className="dropdown-item">
                          {props.t("ICO Landing")}
                        </Link>
                      </div>
                    </div> */}

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setgrade(!grade);
                        }}
                      >
                        {props.t("Evaluation")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: grade,
                        })}
                      >
                        <Link to="/grade/conduct" className="dropdown-item">
                          {props.t("Conduct")}
                        </Link>

                      
                        <div className="dropdown">
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setgrade(!grade);
                             
                            }}
                          >
                            <span key="t-email-templates"> {props.t("Grades")}</span>{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: grade,
                            })}
                          >
                            <Link
                            to="/grade/add"
                              className="dropdown-item"
                            >
                              {props.t("General")}
                            </Link>
                            <Link
                              to="/grade/completive"
                              className="dropdown-item"
                            >
                              {props.t("Completives")}
                            </Link>
                            {/* <Link
                              to="email-template-billing"
                              className="dropdown-item"
                            >
                              {props.t("Billing Email")}
                            </Link> */}
                          </div>
                        </div>




                        <Link to="/grade/comments" className="dropdown-item">
                          {props.t("Comments")}
                        </Link>

                        {/* <Link
                            to="/reports/bulletin"
                            className="dropdown-item"
                          >
                            {props.t("Report Bulletin")}
                          </Link>
                          <Link
                            to="projects-overview"
                            className="dropdown-item"
                          >
                            {props.t("Project Overview")}
                          </Link>
                          <Link to="projects-create" className="dropdown-item">
                            {props.t("Create New")}
                          </Link> */}
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setReports(!reports);
                        }}
                      >
                        {props.t("Reports")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: reports,
                        })}
                      >
                        <Link to="/reports/bulletin" className="dropdown-item">
                          {props.t("Bulletin")}
                        </Link>
                        <Link
                          to="/reports/competence"
                          className="dropdown-item"
                        >
                          {props.t("Competence")}
                        </Link>
                        {/* <Link
                            to="/reports/bulletin"
                            className="dropdown-item"
                          >
                            {props.t("Report Bulletin")}
                          </Link>
                          <Link
                            to="projects-overview"
                            className="dropdown-item"
                          >
                            {props.t("Project Overview")}
                          </Link>
                          <Link to="projects-create" className="dropdown-item">
                            {props.t("Create New")}
                          </Link> */}
                      </div>
                    </div>

                    {/* <div className="dropdown">
                      <Link
                        to="/#"
                        
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          settask(!task);
                        }}
                      >
                        {props.t("Tasks")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: task })}
                      >
                        <Link to="tasks-list" className="dropdown-item">
                          {props.t("Task List")}
                        </Link>
                        <Link to="tasks-kanban" className="dropdown-item">
                          {props.t("Kanban Board")}
                        </Link>
                        <Link to="tasks-create" className="dropdown-item">
                          {props.t("Create Task")}
                        </Link>
                      </div>
                    </div> */}
                    {/* <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setcontact(!contact);
                        }}
                      >
                        {props.t("Contacts")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: contact,
                        })}
                      >
                        <Link to="contacts-grid" className="dropdown-item">
                          {props.t("User Grid")}
                        </Link>
                        <Link to="contacts-list" className="dropdown-item">
                          {props.t("User List")}
                        </Link>
                        <Link to="contacts-profile" className="dropdown-item">
                          {props.t("Profile")}
                        </Link>
                      </div>
                    </div> */}
                    {/* <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={(e) => {
                          e.preventDefault();
                          setBlog(!blog);
                        }}
                      >
                        {props.t("Blog")} <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: blog,
                        })}
                      >
                        <Link to="blog-list" className="dropdown-item">
                          {props.t("Blog List")}
                        </Link>
                        <Link to="blog-grid" className="dropdown-item">
                          {props.t("Blog Grid")}
                        </Link>
                        <Link to="blog-details" className="dropdown-item">
                          {props.t("Blog Details")}
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </li>
                {/* ------------------- */}
                {/* start configuration */}
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setSetting(!setting);
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-cog mr-2"></i>
                    {props.t("Settings")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: setting })}
                  >
                    <div className="dropdown">
                      <Link
                        to="/grade/configuration"
                        className="dropdown-item arrow-none"
                      >
                        {props.t("Grades")}
                      </Link>
                    </div>
                  </div>
                </li>
                {/* end configuration */}
                {role === "admin" && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setAdminApp(!adminApp);
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize mr-2"></i>
                      {props.t("Administration")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: adminApp })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={(e) => {
                            e.preventDefault();
                            setgrade(!grade);
                          }}
                        >
                          {props.t("Users")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: grade,
                          })}
                        >
                          <Link to="/students/list" className="dropdown-item">
                            {props.t("Students")}
                          </Link>

                          {/* <Link to="/grade/add" className="dropdown-item">
                          {props.t("Grades")}
                        </Link> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className={classname("dropdown-menu", { show: adminApp })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={(e) => {
                            e.preventDefault();
                            setgrade(!grade);
                          }}
                        >
                          {props.t("Finances")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: grade,
                          })}
                        >
                          <Link to="/finance/student" className="dropdown-item">
                            {props.t("Status")}
                          </Link>

                          {/* <Link to="/grade/add" className="dropdown-item">
                          {props.t("Grades")}
                        </Link> */}
                        </div>
                      </div>
                    </div>
                  </li>
                )}

                {/* ----------------------- */}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(Navbar))
);
