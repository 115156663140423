import { initialInputState } from "./validate";
const thead = {
  columns: [
    {
      label: "Nombres",
      field: "user_name",
      features: { avatar: true },
    },
    {
      label: "Apellidos",
      field: "user_last_name",
    },
    {
      label: "usuario",
      field: "user_username",
    },
    {
      label: "Curso",
      field: "course_name",
    },
    {
      label: "Telefono",
      field: "user_phone",
    },
    {
      label: "Correo",
      field: "user_email",
    },
    {
      label: "estado",
      field: "status_name",
    },
    {
      label: "Acciones",
      field: "options",
    },
  ],
  rows: [{}],
};

export const initialState = {
  data: thead,
  isMode: "Add",
  isModalOpen: false,
  eachEntry: initialInputState,
  isSubmit: false,
  modalColor: "success",
  id: null,
  error: {},
};

export const reducer = (state, action) => {
  

  if (action.type === "INPUT_CHANGE") {
    return { ...state, eachEntry: action.payload };
  }
  if (action.type === "INPUT_CHANGE") {
    return { ...state, eachEntry: action.payload };
  }
  if (action.type === "SENDING_DATA_TO_SERVER") {
    return {
      ...state,
      isSubmit: true,
    };
  }
  if (action.type === "CREATE_START") {
    return {
      ...state,
      // modalColor: "primary",
      isMode: "Add",
      eachEntry: initialInputState,
      isModalOpen: true,
    };
  }
  if (action.type === "READ") {
    return {
      ...state,
      data: {
        columns: [...state.data.columns],
        rows: [...action.payload.rows, ...state.data.rows],
      },
    };
  }
  if (action.type === "UPDATE_START") {
    return {
      ...state,
      isMode: "Update",
      id: action.payload.student_id,
      eachEntry: action.payload,
      modalColor: "primary",
      isModalOpen: true,
    };
  }
  if (action.type === "DELETE_START") {
    return {
      ...state,
      isMode: "Delete",
      id: action.payload.student_id,
      modalColor: "danger",
      isModalOpen: true,
    };
  }
  if (action.type === "ADDED") {
    return {
      ...state,
      data: {
        columns: [...state.data.columns],
        rows: [...action.payload.rows, ...state.data.rows],
      },
    };
  }
  if (action.type === "UPDATED") {
    return {
      ...state,
      data: {
        columns: [...state.data.columns],
        rows: state.data.rows.map(
          (obj) =>
            action.payload.rows.find((o) => o.student_id === obj.student_id) ||
            obj
        ),
      },
    };
  }
  if (action.type === "DELETED") {
    return {
      ...state,
      data: {
        columns: [...state.data.columns],
        rows: state.data.rows.filter((el) => {
          return el.student_id !== state.id;
        }),
      },
    };
  }
  if (action.type === "SET_ERRORS") {
    return {
      ...state,
      errors: action.payload,
    };
  }
  if (action.type === "RESET_AN_ENTRY_ERROR") {
    const { [action.payload]: value, ...newErrors } = state.errors;
    return {
      ...state,
      errors: newErrors,
    };
  }

  if (action.type === "INITIAL_STATE") {
    return {
      ...state,
      isMode: "Add",
      isModalOpen: false,
      eachEntry: initialInputState,
      isSubmit: false,
      modalColor: "success",
      id: null,
      errors: {},
    };
  }
};
