import React from 'react'

const AcademicCondition = ({condition}) => {
    
    return (
      <>
        <table
          className="table-condition"
          width="100%"
          border={0}
          cellSpacing={0}
          cellPadding={0}
        >
          <tbody>
            <tr>
              <td className="condicion font-weight-bold pl-2" colSpan={16}>
                Condición Académica{" "}
              </td>
            </tr>
            <tr>
              <td className="evaluacion text-center" colSpan={4}>
                1ra Eval.
              </td>
              <td className="evaluacion text-center" colSpan={4}>
                2da Eval.
              </td>
              <td className="evaluacion text-center" colSpan={4}>
                3ra Eval.
              </td>
              <td className="evaluacion text-center" colSpan={4}>
                4ta Eval.
              </td>
            </tr>
            <tr>
              <td className="notas  text-center" colSpan={4}>
                <strong> {condition.p1}</strong>
              </td>
              <td className="notas text-center" colSpan={4}>
                <strong>{condition?.p2}</strong>
              </td>
              <td className="notas text-center" colSpan={4}>
                <strong>{condition?.p3}</strong>
              </td>
              <td className="notas text-center" colSpan={4}>
                <strong>{condition?.p4}</strong>
              </td>
            </tr>
          </tbody>
          <tbody></tbody>
        </table>
      </>
    );
}

export default AcademicCondition
