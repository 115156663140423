import React from "react";

const Leyenda = () => {
/*  return (
    <>
      <table className="table-leyenda" width="100%">
        <tbody>
          <tr>
            <td className="notas">
              <strong>Leyenda </strong>
            </td>
          </tr>
          <tr>
            <td className="notas">NOTA PROMOCIÓN: 70</td>
          </tr>
          <tr>
            <td className="notas">
              <strong>HONOR DANIEL:</strong> 94 (Todas las notas sobre 80/
              Conducta mínima 85) <strong>HONOR DAVID:</strong> 90 (Todas las
              notas sobre 80/ notas de conducta mínima 85)
            </td>
          </tr>
          <tr>
            <td className="notas">
              <strong>PRUEBA ACADÉMICA:</strong> 3 notas por debajo de 70{" "}
              <strong>PRUEBA CONDUCTUAL:</strong> 3 notas de conducta por debajo
              de 70 y/o falta gravísima
            </td>
          </tr>
          <tr>
            <td>
              <strong>NOTA</strong> Para calcular los promedios se utiliza una
              formula de promedio ponderado en base a la carga académica
              asignada a cada materia (cantidad de periodos de clase por semana,
              actualizada por pandemia)
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );*/

  return (
    <>
      <table className="table-leyenda" width="100%">
        <tbody>
          <tr>
            <td className="notas honores" rowSpan="2">
              <strong>Honores </strong>
            </td>
            <td className="notas" rowSpan="2">
              <div>- Todas las notas sobre 80/ Conducta mínima 85.</div>
              <div>- No haber cursado ninguna asignatura en Recuperación Pedagógica.</div>
              <div>
                - <strong>Nota:</strong> el cálculo de los promedios es
                ponderado en base a la carga académica asignada a cada materia.
              </div>
            </td>
            <td className="notas">
              HONOR <strong>DANIEL</strong> promedio final de{" "}
              <strong>94-100</strong>
            </td>
          </tr>
          <tr>
            <td className="notas">
              HONOR <strong>DAVID</strong> promedio final de{" "}
              <strong>90-93</strong>
            </td>
          </tr>
          <tr>
            <td className="notas honores">
              <strong>Prueba Académica-Conductual:</strong>
            </td>
            <td className="notas" colSpan="2">
              <div>
                - Tres (3) notas por debajo de 70 (Académica y/o Conductual).
              </div>
              <div>
                - Una falta <strong>muy grave</strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Leyenda;
