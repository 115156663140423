import {initialInputState} from './validate'

export const initialState = {
  eachEntry: initialInputState,
  optionsCourses: [],
  optionsSubjects: [],
  error: {},
  dataTable: null,
  isDataLoading: false,
  courseSelectUrl: "courses/select/3",
};

export const reducer = (state, action) => {
  

  if (action.type === "INPUT_CHANGE") {
    return { ...state, eachEntry: action.payload };
  }
  if (action.type === "READ_SELECT_SUCCESS") {
    return { ...state, optionsCourses: action.payload };
  }
   if (action.type === "LOADING_TABLE") {
     return { ...state, isDataLoading: true };
   }
 if (action.type === "RESET_OPTIONS") {
return {
  ...state,
  eachEntry: {
    ...state.eachEntry,
       subject_id: { ...state.eachEntry?.subject_id, value: "", errors: "" },
  },
  optionsSubjects: [],
  dataTable: null,
};

 }


   
  if (action.type === "SET_DEPENDABLE_OPTIONS") {
    return {
      ...state,
      optionsSubjects:action.payload.optionsSubjects,
      
    };
  }
  if (action.type === "SET_DATA_TABLE") {
    
    return {
      ...state,
      isDataLoading: false,
      dataTable: action.payload.dataTable,
    };
  }
   if (action.type === "CHECKBOX_CHANGE") {
     return {
       ...state,

       dataTable: {
         columns: [...state.dataTable.columns],
         rows: action.payload,
       },
     };
   }
  if (action.type === "SET_ERRORS") {
    return {
      ...state,
      errors: action.payload,
    };
  }
  if (action.type === "RESET_AN_ENTRY_ERROR") {
    const { [action.payload]: value, ...newErrors } = state.errors;
    return {
      ...state,
      errors: newErrors,
    };
  }

  

  if (action.type === "INITIAL_STATE") {
    return {
      ...state,
      eachEntry: initialInputState,
      modalColor: "success",
      id: null,
      errors: {},
    };
  }
};
