export const selectOptions = (data, value, label) => {
  return data.map((element) => ({
    value: element[value],
    label: element[label],
  }));
};


export const generateOptions = (data, valueKey, labelCallback) => {
  const options = data.map((element) => ({
    value: element[valueKey],
    label: labelCallback(element),
  }));
  // Return the array of option objects
  return options;
};
