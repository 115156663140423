import React from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";


 const SocialSource = (props) => {

            const  socials = [
                { title: "Facebook", bgColor: "bg-primary", iconClass: "mdi-facebook", description: "125" },
                { title: "Instagram", bgColor: "bg-pink", iconClass: "mdi-instagram", description: "104" },
                { title: "Youtube", bgColor: "bg-danger", iconClass: "mdi-youtube", description: "112", url:"https://www.youtube.com/channel/UCorLYek81M8PJ5C5FBOAKtA" },
            ];

          return (
            <React.Fragment>
              <Card>
                <CardBody>
                  <CardTitle >{props.t("Social Media")}</CardTitle>
                  <Row>
                    {socials.map((social, key) => (
                      <Col xs="4" key={"_li_" + key}>
                        <div className="social-source text-center mt-2">
                          <div className="avatar-xs mx-auto mb-3">
                            <span
                              className={
                                "avatar-title rounded-circle " +
                                social.bgColor +
                                " font-size-16"
                              }
                            >
                              <i
                                className={
                                  "mdi " + social.iconClass + " text-white"
                                }
                              ></i>
                            </span>
                          </div>
                          <h5 className="font-size-14">{social.title}</h5>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </React.Fragment>
          );
        }

export default SocialSource;