import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginations = ({ dataPerPage, totalData, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
    pageNumbers.push(i);
  }

  let lastPage = pageNumbers.slice(-1)[0];

  return (
    <>
      <Pagination className="pagination pagination-rounded justify-content-end mb-2">
        <PaginationItem disabled={currentPage === 1 ? true : false}>
          <PaginationLink
            previous
            onClick={(e) => {
              paginate(e, currentPage - 1);
            }}
            href="#"
          />
        </PaginationItem>

        {pageNumbers.slice(currentPage - 1, currentPage + 5).map((number) => (
          <PaginationItem
            key={number}
            className={
              currentPage === number ? "page-item active" : "page-item"
            }
          >
            <PaginationLink
              href="#"
              onClick={(e) => {
                paginate(e, number);
              }}
            >
              {" "}
              {number}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem disabled={currentPage === lastPage ? true : false}>
          <PaginationLink
            onClick={(e) => {
              paginate(e, currentPage + 1);
            }}
            next
            href="#"
          />
        </PaginationItem>
      </Pagination>
    </>
  );
};

export default Paginations;
