export const initialInputState = {
  course_id: {
    label: "Course",
    value: "",
    errors: [],
    type: "select",
    rules: {
      require: true,
    },
  },

 
  subject_id: {
    label: "Periods",
    value: "",
    errors: [],
    type: "select",
    rules: {
      require: true,
    },
  },
};
