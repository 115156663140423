import React from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { reducer, initialState } from "./reducer";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
 import SelectFetch from "../../../../components/Common/SelectFetch";
 import useModuleSelect from "../../../../hooks/useModuleSelect";
const PeriodSetting = (props) => {

const { state, handleInputChange,find } =
  useModuleSelect({
    reducer,
    initialState,
  });

// console.log(state);
const {
  eachEntry,
  optionsPeriods,

  dataTable,
  isDataLoading,
  errors,
} = state;

const { level_id } = eachEntry;




  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Settings")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("Periods Settings")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("Fill all information below")}
                </CardSubtitle>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>{props.t("Levels")}</Label>
                      <SelectFetch
                        url={`/levels/select`}
                        minMenuHeight={600}
                        value={level_id.value}
                        onChange={(selected) => {
                          handleInputChange("level_id", selected);
                          // getOptions(`courses/selects/${selected.value}`);
                        }}
                        placeholder={props.t("Select a Level")}
                      />
                      <small className="text-danger">
                        {errors?.student_id}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Button
                        onClick={() => {
                          // let url = `/grades/comments/course/${course_id.value.value}/period/${period_id.value.value}`;
                          // find(url);
                        }}
                        type="submit"
                        color="primary"
                        className=" mt-4 waves-effect waves-light"
                        disabled={isDataLoading}
                      >
                        {props.t("Find")}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withNamespaces()(PeriodSetting);
