import React from "react";

export const Pages = ({ setDataPerPage }) => {
  const handleSelect = (e) => {
    setDataPerPage(e.target.value);
  };
  return (
    <>
      <select
        defaultValue={10}
        onChange={(e) => handleSelect(e)}
        className="browser-default custom-select"
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
      </select>
    </>
  );
};
