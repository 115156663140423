import React from "react";

const Signatures = ({data}) => {
  return (
    <div className="pt-2">
      <table width="100%">
        <tbody>
          <tr>
            <td align="center" className="border-bottom border-dark">
              <img src={data?.teacher_signature} alt="firma" width="140px" />
            </td>
            <td></td>
            <td align="center" className="border-bottom border-dark">
              <img src={data?.coordinator_signature} alt="firma" width="140px" />
            </td>
            <td></td>
            <td align="center" className="border-bottom border-dark">
              <img src={data?.director_signature} alt="firma" width="140px" />
            </td>
          </tr>
          <tr>
            <td className="text-center">
              Profesor Titular:
              <br />
              {data?.teacher_name + "  " + data?.teacher_last_name}
            </td>
            <td></td>
            <td className="text-center">
              Coordinadora Académica: <br />
              {data.coordinator_name + "  " + data?.coordinator_last_name}
            </td>
            <td></td>
            <td className="text-center">
              Directora General: <br />
              Loren G. de Yabra, M.Ed.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Signatures;
