import React from "react";
import logo_ccmb from "../../../../assets/images/logos/logo_ccmb.jpg";
import Signatures from "../../Bulletin/Signatures";
import { Button } from "reactstrap";
import "./inicial.css";
import { handleIconCriteria } from "./Criteria";
const InicialCompetence = ({ data,generatePDF }) => {

  let courseId = data?.course_id
  return (
    <>
      <hr className=" p-4 d-print-none"></hr>
      <table className="headertop" width="100%">
        <tbody>
          <tr>
            <td valign="top" align="left" width="15%">
              <img src={logo_ccmb} alt="ccmb" width={100} />
            </td>
            <td className="cabezal" width="35%">
              Excelencia Fundamentada en la Roca.
            </td>
            <td valign="top" align="center" width="15%">
              <img src={data?.avatar} alt="students" width={80}></img>
            </td>
            <td className="names" width="35%">
              {data?.user_name + " " + data?.user_last_name}
              <br />
              {data?.student_code ? data?.student_code : "0000-00000-000"}
              <br />
              {data?.course_name}
              <Button
                className=" mt-4 ml-2 waves-effect waves-light"
                color="success"
                onClick={() => generatePDF(data)}
              >
                <i className="fa fa-print"></i>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>

      <table width="100%" className="p-2">
        <tbody>
          <tr>
            <td width="100%" className="text-center font-weight-bold p-2">
              Reporte de Evaluación de Desempeño
            </td>
          </tr>
        </tbody>
      </table>
      <table
        width="100%"
        // classname="gama"
        border="{0}"
        cellSpacing="{0}"
        cellPadding="10px"
      >
        <tbody>
          <tr>
            <td className="font-weight-bold">Leyenda:</td>
            <td>
              <span className="fas fa-star pr-2"> </span> Logrado Excepcional
            </td>
            <td>
              <span className="fas fa-check pr-2"></span>
              Logrado
            </td>
            <td>
              <span className="fas fa-minus pr-2"></span>
              En Proceso
            </td>
            <td>
              <span className="fas fa-circle pr-2"></span>
              Iniciado
            </td>
          </tr>
        </tbody>
      </table>
      {data?.student_status_id === "1" || data?.role_id < 3 ? (
        <>
          <table
            className="mt-10 table-border rallitas"
            width="100%"
            border={0}
            cellSpacing={0}
            cellPadding={0}
          >
            <tbody>
              {data?.subjects &&
                data.subjects.map((row, index) => {
                  return (
                    <>
                      <tr key={index + "subjects"}>
                        <td colSpan={7} className="subject">
                          {row.name}
                        </td>
                      </tr>

                      {row.benchmarks.map((brow, bindex) => {
                        return (
                          <React.Fragment key={bindex + "benchmarks"}>
                            <tr>
                              <td
                                className="pl-2"
                                colSpan={3}
                                bgcolor="#C2E49C"
                              >
                                {brow.name}
                              </td>
                              <td
                                className="text-center"
                                bgcolor="#C2E49C"
                                width="7.5%"
                              >
                                P1
                              </td>
                              <td
                                className="text-center"
                                bgcolor="#C2E49C"
                                width="7.5%"
                              >
                                P2
                              </td>
                              <td
                                className="text-center"
                                bgcolor="#C2E49C"
                                width="7.5%"
                              >
                                P3
                              </td>
                              {courseId == 5 && (
                                <td
                                  className="text-center"
                                  bgcolor="#C2E49C"
                                  width="7.5%"
                                >
                                  P4
                                </td>
                              )}
                            </tr>
                            {brow?.activities &&
                              brow.activities.map((arow, aindex) => {
                                return (
                                  <React.Fragment key={aindex + "activities"}>
                                    <tr>
                                      <td className="pl-2">
                                        {arow.competence_code}
                                      </td>
                                      <td className="pl-2" colSpan={2}>
                                        {arow.competence_name}
                                      </td>
                                      <td className="text-center" width="7.5%">
                                        {handleIconCriteria(arow?.P1)}
                                      </td>
                                      <td className="text-center" width="7.5%">
                                        {" "}
                                        {handleIconCriteria(arow?.P2)}
                                      </td>
                                      <td className="text-center" width="7.5%">
                                        {" "}
                                        {handleIconCriteria(arow?.P3)}
                                      </td>
                                      {courseId == 5 && (
                                        <td
                                          className="text-center"
                                          width="7.5%"
                                        >
                                          {" "}
                                          {handleIconCriteria(arow?.P4)}
                                        </td>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                    </>
                  );
                })}
            </tbody>
          </table>
          <div className="mt-2">
            <ul className="list-unstyled">
              {data?.comments &&
                data?.comments.map((row, index) => (
                  <li key={index}>
                    <strong>Comentario P{row.comment_period_id} </strong>:{" "}
                    {row.comment_description}
                  </li>
                ))}
            </ul>
          </div>
          <Signatures data={data} />
        </>
      ) : (
        <div>
          <div className="text-center">
            <span style={{ fontSize: "3em", color: "Tomato" }}>
              <i className="fas fa-exclamation-triangle fa-3x" />
            </span>
          </div>

          <h2 className="text-center font-weight-bold">
            Por favor comunícate con la Administración
          </h2>
        </div>
      )}
    </>
  );
};

export default InicialCompetence;
