import React, { useReducer,useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { mainApi, apiv2} from "../../../services/api";
import { reducer, initialState } from "./reducer";
import SelectFetch from "../../../components/Common/SelectFetch";
import PrimariaBulletin from "../Bulletin/Primaria/PrimariaBulletin";
import SecundariaBulletin from "../Bulletin/Secondaria/SecundariaBulletin";
import Select from "react-select";

import { handleValidationBeforeSubmit } from "../../../helpers/validator";
import TextTransition, { presets } from "react-text-transition";
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { generateOptions } from "../../../helpers/Selects";


const Bulletin = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    eachEntry,
    optionsPeriods,
    isLoading,
    errors,
    studentData,
    typeOfBulletin,
    literal,
  } = state;
  const { course_id, period_id, search } = eachEntry;

  /*____________________________________________________________________________________________________________________________________________________ */
  const [isDivDisabled, setIsDivDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [url, setUrl] = useState("");

  const TEXTS = [
    "Sending the data...",
    "Verifying data...",
    "Generating pdf...",
    "Generating pdf...",
    "Generating pdf...",
  ];

  React.useEffect(() => {
    if (isModalOpen) {
      const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        4000 // every 4 seconds
      );

      return () => clearTimeout(intervalId);
    }
  }, [isModalOpen]);
  const openInNewTab = (url) => {
    setIsDivDisabled(false);
    window.open(url, "about:blank", "noopener,noreferrer");
  };
  const toggle = () => {
    setIndex(0);
    setIsModalOpen(!isModalOpen);
    setUrl("");
    setIsDivDisabled(false);
  };
  /*____________________________________________________________________________________________________________________________________________________ */

  const generatePDF = async (data) => {
    data = JSON.stringify(data);

    setIsDivDisabled(true);

      await mainApi
      .post("pdf/individual", data)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          openInNewTab(res.data.url);
        }
      })
      .catch((err) => {
        alert("Imprimir en PDF no disponible en este momento");
        setIsDivDisabled(false);
      });
  };
  /*____________________________________________________________________________________________________________________________________________________ */

  const generatePDFAll = async (data) => {
    data = JSON.stringify(data);

    setIsDivDisabled(true);

    await mainApi
      .post("pdf/bulletin", data)
      .then((res) => {
        setIsModalOpen(true);
        if (res.data.success) {
          setUrl(res.data.url);
        }
      })
      .catch((err) => {
        alert("Imprimir en PDF no disponible en este momento");
        setIsDivDisabled(false);
        setIsModalOpen(false);
      });
  };

  /*____________________________________________________________________________________________________________________________________________________ */
  const handleInputChange = (key, value) => {
    if (errors?.[key]) {
      dispatch({ type: "RESET_AN_ENTRY_ERROR", payload: [key] });
    }
    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        ...eachEntry,
        [key]: { ...eachEntry?.[key], value: value },
      },
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const getOptions = (gradeId) => {
    dispatch({
      type: "RESET_OPTIONS",
    });

    apiv2.get(`grades/${gradeId}/periods`).then((response) => {
      if (response.data) {
        console.log(response.data);
        dispatch({
          type: "SET_DEPENDABLE_OPTIONS",
          payload: generateOptions(response.data, "id", (item) => item.name),
        });
      }
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const getBulletinReport = () => {
    const checkErrors = handleValidationBeforeSubmit(eachEntry);
    if (JSON.stringify(checkErrors) !== "{}") {
      dispatch({ type: "SET_ERRORS", payload: checkErrors });
    } else {
      if (isLoading) {
        return;
      }
      dispatch({ type: "LOADING" });
      //api2.ccmbtareas.com/reports/grade/14

      apiv2
        .get(
          `/reports/grade/${course_id.value.value}/period/${period_id.value.value}/bulletin`
        )
        .then((response) => {
          dispatch({
            type: "LOAD_ALL_STUDENT",
            payload: response.data,
          });
        });
    }
  };
  const applyCriteria = () => {
    if (literal) {
      dispatch({ type: "NUMERIC_ENABLED" });
    } else {
      dispatch({ type: "LITERAL_ENABLED" });
    }

    // let changeRows = studentData.map((item) => {
    //   item.p_1 = item.p_1.map((row) => {
    //     let obj = {
    //       subject_name: row.subject_name,
    //       final_grade: criteria(row.final_grade),
    //     };

    //     return obj;
    //   });
    //   return item;
    // });

    // dispatch({
    //   type: "APPLY_CRITERIA",
    //   payload: changeRows,
    // });
  };

  return (
    <div className="page-content">
      <Container
        fluid
        style={isDivDisabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
      >
        <Breadcrumbs
          title={props.t("Reports")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle className="d-print-none">
                  {props.t("Bulletin")}
                </CardTitle>
                <CardSubtitle className="mb-3 d-print-none">
                  {props.t("Fill all information below")}
                </CardSubtitle>
                <Row className="d-print-none">
                  <Col lg="3">
                    <FormGroup>
                      <Label>{props.t("Courses")}</Label>
                      <SelectFetch
                        url={"courses/select/all"}
                        minMenuHeight={600}
                        value={course_id.value}
                        onChange={(selected) => {
                          handleInputChange("course_id", selected);
                          getOptions(selected.value);
                        }}
                        placeholder={props.t("Select a Course")}
                      />
                      <small className="text-danger">{errors?.course_id}</small>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="select2-container">
                      <Label>{props.t("Periods")}</Label>
                      <Select
                        menuPortalTarget={document.body}
                        value={period_id.value}
                        onChange={(selected) =>
                          handleInputChange("period_id", selected)
                        }
                        options={optionsPeriods}
                        placeholder={props.t("Select a Period")}
                      />
                      <small className="text-danger">{errors?.period_id}</small>
                    </FormGroup>
                  </Col>

                  <Col lg="3">
                    <FormGroup>
                      <Button
                        onClick={getBulletinReport}
                        type="submit"
                        color="primary"
                        className=" mt-4 waves-effect waves-light"
                        disabled={isLoading}
                      >
                        {props.t("Find")}
                      </Button>
                      {studentData && (
                        <Button
                          onClick={() => {
                            // printInvoice();
                            generatePDFAll(studentData);
                          }}
                          color="success"
                          className=" mt-4 ml-2 waves-effect waves-light"
                          disabled={isLoading}
                        >
                          <i className="fa fa-print"></i>
                        </Button>
                      )}
                      {typeOfBulletin === "Primaria" && (
                        <Button
                          onClick={() => {
                            applyCriteria();
                          }}
                          color={literal ? "info" : "warning"}
                          className=" mt-4 ml-2 waves-effect waves-light"
                        >
                          {literal ? props.t("Numeric") : props.t("Literal")}
                        </Button>
                      )}
                    </FormGroup>
                  </Col>
                  {studentData && (
                    <Col lg="3">
                      <FormGroup className="select2-container">
                        <Label>{props.t("Filter Students")}</Label>
                        <Input
                          placeholder={props.t("Search students...")}
                          value={search.value}
                          onChange={(e) => {
                            handleInputChange("search", e.target.value);
                          }}
                        />
                        <small className="text-danger">{errors?.search}</small>
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                {isLoading && (
                  <div>
                    Sincronizando los datos
                    <Spinner type="grow" color="info" />
                  </div>
                )}
                {typeOfBulletin && (
                  <>
                    {typeOfBulletin === "Inicial" && (
                      <div className="text-warning">
                        No existen datos para el nivel inicial
                      </div>
                    )}
                    {typeOfBulletin === "Primaria" &&
                      studentData
                        .filter((x) => {
                          return (
                            x.user_name
                              .toLowerCase()
                              .indexOf(search.value.toLowerCase()) !== -1
                          );
                        })
                        .map((t, k) => {
                          return (
                            <PrimariaBulletin
                              literal={literal}
                              generatePDF={generatePDF}
                              key={k}
                              data={t}
                            />
                          );
                        })}

                    {typeOfBulletin === "Secundaria" &&
                      studentData
                        .filter((x) => {
                          return (
                            x.user_name
                              .toLowerCase()
                              .indexOf(search.value.toLowerCase()) !== -1
                          );
                        })
                        .map((t, k) => {
                          return (
                            <div key={k} className="mt-0">
                              <SecundariaBulletin
                                data={t}
                                key={k}
                                generatePDF={generatePDF}
                              />
                            </div>
                          );
                        })}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={isModalOpen}
        toggle={toggle}
        backdrop={"static"}
        keyboard={false}
        size={"s"}
        centered
      >
        {/* <ModalHeader toggle={toggle}>{props.t(mode)}</ModalHeader> */}
        <ModalBody>
          <h2>
            {url
              ? props.t("Files Generated Successfully")
              : props.t("Please wait...")}
          </h2>
          {!url && (
            <TextTransition
              text={props.t(TEXTS[index % TEXTS.length])}
              springConfig={presets.wobbly}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {/* <Button color={modalColor} onClick={handleSubmit}>
            {props.t(mode)}
          </Button> */}
          {url && (
            <Button
              color="success"
              onClick={() => {
                toggle();
                openInNewTab(url);
              }}
            >
              {props.t("View files")}
            </Button>
          )}

          <Button color="danger" onClick={toggle}>
            {props.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      {/* {isModalOpen && (
        <SweetAlert
          title={props.t("Please wait...")}
          onConfirm={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
        >
          {url && <Button onClick={() => openInNewTab(url)}>Abrir</Button>}

          <Spinner color="primary" />
          <Spinner color="secondary" />
          <Spinner color="success" />
        </SweetAlert>
      )} */}
    </div>
  );
};
export default withNamespaces()(Bulletin);