import axios from "axios";

// import { LOGOUT_USER } from "../store/auth/login/actionTypes";
// import store  from "../store";
export const mainApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,

});

export const apiv2 = axios.create({
  baseURL: process.env.REACT_APP_APIV2_URL,
});

mainApi.interceptors.request.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log(response);
    const token =
      localStorage.getItem("authUser") !== null
        ? JSON.parse(localStorage.getItem("authUser")).token
        : "";

        // if (token) {
        //   console.log('inside of logout')
        //   store.dispatch({ type: LOGOUT_USER, payload: "logout" });
        //   // return
        // } else {
          
        // }
        response.headers.Authorization = token;
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
mainApi.interceptors.response.use(
  function (response) {
    // Do something before request is sent
    // console.log(response.data.token);
    // localStorage["authUser"].token = response.data.token;
    // var existing = JSON.parse(localStorage.getItem("authUser"));
    // existing.token = response.data.token;
    //  localStorage.setItem("authUser", JSON.stringify(existing));
   // console.log(response);

    // if (response.data?.token === "expired") {
    //   //localStorage.clear();

    //   store.dispatch({ type: LOGOUT_USER, payload: "logout" });
    // }
    return response;
  },
  function (error) {
    console.log(error);
    // Do something with request erro
    return Promise.reject(error);
  }
);



//-------------------------------------------------


apiv2.interceptors.request.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log(response);
    const token =
      localStorage.getItem("authUser") !== null
        ? JSON.parse(localStorage.getItem("authUser")).token
        : "";

    // if (token) {
    //   console.log('inside of logout')
    //   store.dispatch({ type: LOGOUT_USER, payload: "logout" });
    //   // return
    // } else {

    // }
    response.headers.Authorization = token;
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
apiv2.interceptors.response.use(
  function (response) {
    // Do something before request is sent
    // console.log(response.data.token);
    // localStorage["authUser"].token = response.data.token;
    // var existing = JSON.parse(localStorage.getItem("authUser"));
    // existing.token = response.data.token;
    //  localStorage.setItem("authUser", JSON.stringify(existing));
    // console.log(response);

    // if (response.data?.token === "expired") {
    //   //localStorage.clear();

    //   store.dispatch({ type: LOGOUT_USER, payload: "logout" });
    // }
    return response;
  },
  function (error) {
    console.log(error);
    // Do something with request erro
    return Promise.reject(error);
  }
);