import React from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { reducer, initialState } from "./reducer";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  Button,

} from "reactstrap";
import DataTable from "../../../components/Common/DataTable";
import useModuleSelect from "../../../hooks/useModuleSelect";
import ModuleSelect from "../../../components/Common/ModuleSelect";
import { mainApi } from "../../../services/api";
import Toast from "../../../helpers/toastr";
const GradeComments = (props) => {
  const {
    state,
    find,
    handleInputChange,
    getOptions,
    hangleOnchange,
 
  } = useModuleSelect({
    reducer,
    initialState,
  });

  // console.log(state);
  const {
    eachEntry,
    optionsPeriods,
    
    dataTable,
    isDataLoading,
    errors,
  } = state;

  const {  period_id, course_id } = eachEntry;

  /*____________________________________________________________________________________________________________________________________________________ */
const handleSubmit = (e) => {
  e.preventDefault();
  let input = e.target.firstChild;

  const formData = new FormData();
  let value = input.value;
  let id = input.getAttribute("dataid");
  let name = input.getAttribute("dataname");
 
  formData.append(name, value);
  formData.append("comment_student_id", id);
  formData.append("comment_period_id", period_id.value.value);
  mainApi.post(`/grade/comments`, formData).then((response) => {
    if (response.data.success) {
      Toast("success", "Comentario", response.data.message);
    } else {
      Toast("error", "Comentario", response.data.message);
    }
  });
};

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Grade")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody className="pt-2">
                <CardTitle>{props.t("Add Grades Comments")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("Fill all information below")}
                </CardSubtitle>
                <ModuleSelect
                  courseSelectUrl={"courses/select/3"}
                  props={props}
                  handleInputChange={handleInputChange}
                  getOptions={getOptions}
                  eachEntry={eachEntry}
                  errors={errors}
                  subject={false}
                   period ={true}
                  actions={
                    <Button
                      onClick={() => {
                        let url = `/grades/comments/course/${course_id.value.value}/period/${period_id.value.value}`;
                        find(url);
                      }}
                      type="submit"
                      color="primary"
                      className=" mt-4 waves-effect waves-light"
                      disabled={isDataLoading}
                    >
                      {props.t("Find")}
                    </Button>
                  }
                  optionsPeriods={optionsPeriods}
                  isDataLoading={isDataLoading}
                  //student={course_id && course_id?.value.value < 6 && true}
                />

                {isDataLoading && (
                  <div>
                    Sincronizando los datos
                    <Spinner size="sm" type="grow" color="info" />
                  </div>
                )}

                {dataTable && !isDataLoading && (
                  <>
                    <div className="table-responsive">
                      <DataTable
                        data={dataTable}
                        onChange={hangleOnchange}
                        onSubmit={handleSubmit}
                      />
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withNamespaces()(GradeComments);
